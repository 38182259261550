import ThinArrowRight from '@components/bits/icons/ThinArrowRight';
import Link from 'next/link';
import React, { FunctionComponent } from 'react';

import styles from './index.module.scss';
type Props = { cta: { title; route } };

const LinkBanner: FunctionComponent<Props> = ({ cta: { title, route } }) => {
  return (
    <Link href={`/${route?.slug?.current}`} className={styles.root}>
      <div className={styles['wrapper-outer']}>
        <span className={styles['wrapper-inner']}>
          <span>{title}</span>
          <ThinArrowRight />
        </span>
      </div>
    </Link>
  );
};

export default LinkBanner;
