import TallArrowDown from '@bits/icons/TallArrowDown';
import SimpleBlockContent from '@bits/SimpleBlockContent';
import React, { FunctionComponent, MouseEvent, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

import styles from './index.module.scss';
type Props = { videoURL; heroText };

const scrollDownPixels = (pixels, speed, ease): void => {
  const checkValue = Math.floor(pixels * 0.99);

  // cancel if already on top
  if (document.scrollingElement.scrollTop >= checkValue) return;

  let scrollY = document.scrollingElement.scrollTop,
    oldTimestamp = null;

  const step = (newTimestamp): void => {
    if (oldTimestamp !== null) {
      scrollY += ((pixels - scrollY) / speed) * ease;

      if (scrollY >= checkValue) {
        document.scrollingElement.scrollTop = pixels;
        return;
      }
      document.scrollingElement.scrollTop = scrollY;
    }
    oldTimestamp = newTimestamp;
    window.requestAnimationFrame(step);
  };
  window.requestAnimationFrame(step);
};

const Hero: FunctionComponent<Props> = ({ videoURL, heroText }): JSX.Element => {
  const [play, setPlay] = useState(false);

  const scrollAbitDown = (e: MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    scrollDownPixels(450, 5, 0.6);
  };

  // Only render video on the client, to avoid hydration issues
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={() => console.log('key')}
      className={`${styles.root} ${play ? styles['playing'] : ''}`}
      onClick={() => setPlay(!play)}>
      <div className={styles['center-wrapper']}>
        <ReactPlayer
          url={videoURL}
          height={'100%'}
          width={'100vw'}
          loop={true}
          playing={play}
          muted
          playsinline={true}
          onReady={() => setPlay(true)}
        />

        <div className={styles['wrapper-outer']}>
          <div className={styles['wrapper-inner']}>
            <div className={styles.desktopContent}>
              <SimpleBlockContent data={heroText} /> <br />
            </div>
          </div>
        </div>
      </div>
      <button className={styles.scroll} onClick={scrollAbitDown}>
        <div className={styles.mobileContent}>
          <SimpleBlockContent data={heroText} />
          <TallArrowDown />
        </div>
        <TallArrowDown />
      </button>
    </div>
  );
};

export default Hero;
